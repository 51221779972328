const img = '';

export default [
  {
    img: img,
    title: 'Awal Pertemuan',
    description: `Berawal dari abang mencari tahu saya dari teman saya yang kebetulan berteman juga dengan saya, lanjut komunikasi saling mengenal melalui WhatsApp, first time bertemu 21 Maret 2021, seminggu kemudian kita berkomitmen untuk menjalin hubungan yang lebih serius, 6 Mei 2021 abang datang kerumah untuk berkenalan dengan keluarga dan orangtua dan komunikasi berlanjut terus.`,
  },
  {
    img: img,
    title: 'Lamaran',
    description: `Setelah beberapa tahun berkomitmen memantapkan diri, abang berbicara kepada saya dan kepada orangtua saya untuk melamar saya, hingga akhir nya pada 15 Juli 2023 abang datang bersama keluarga untuk meminang saya.
    <br />
    Setelahnya kita LDR, abang berangkat bekerja. Banyak ujian yang kami lewati bersama untuk menuju pernikahan.
    <br />
    H-1 bulan abang pulang dan kami meyiapkan segala sesuatu keperluan pernikahan, and finally impian dan restu dari kedua orangtua kami, inshaallah kami akan melaksanakan pernikahan pada 24 Februari 2024.`,
  },
];
