import React from 'react';
import { Box, AspectRatio, Text, Button, Center, Link, Heading } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';

import { THE_BRIDE, YOUTUBE_EMBED, YOUTUBE_LINK } from '@/constants';

import txt from './locales';
import useLang from '@/hooks/useLang';
import { HEADING_STYLES } from '@/constants/colors';
import useInvitation from '@/hooks/useInvitation';
import { ID_YOUTUBE_SECTION } from '@/constants/identifier';

function YoutubeLiveSection({ ...rest }) {
  const defaultLang = useLang();
  const LANG = defaultLang;
  const isInvitation = useInvitation();

  return (
    <Box paddingTop={isInvitation ? '12px' : '42px'} name={ID_YOUTUBE_SECTION} bgColor="bgPrimary">
      <Heading {...HEADING_STYLES} paddingLeft="0px" textAlign="center" margin="0 0 20px 0">
        {txt.title[LANG]}
      </Heading>
      <Box zIndex="2" position="relative" bgColor="bgPrimary" {...rest}>
        <Box width="100%" bgColor="bgSecondary" minHeight="120px" />
        {/* Title & Desctiption Section */}
        <Box zIndex="2" textAlign="center" position="relative" padding="32px 24px 32px 24px">
          <WithAnimation>
            <AspectRatio
              maxW="560px"
              ratio={16 / 9}
              borderRadius="lg"
              boxShadow="xl"
              marginTop="-110px"
            >
              <iframe
                title={`Prewedding of ${THE_BRIDE}`}
                src={YOUTUBE_EMBED}
                allowFullScreen
                loading="lazy"
                style={{ borderRadius: '8px' }}
              />
            </AspectRatio>
          </WithAnimation>
          {/* Live stream section */}
          {false && (
            <Box padding="22px 16px">
              <WithAnimation>
                <Text textAlign="center" fontSize="md" fontFamily="serif" color="mainColorText">
                  {txt.problem[LANG]}
                </Text>
              </WithAnimation>
              <Center>
                <Link href={YOUTUBE_LINK} target="_blank">
                  <Button
                    size="sm"
                    fontWeight="normal"
                    color="mainColorTextLight"
                    marginTop="14px"
                    fontSize="md"
                    bgColor="bgAlternative"
                    colorScheme="blackAlpha"
                    fontStyle="italic"
                  >
                    {txt.open[LANG]}
                  </Button>
                </Link>
              </Center>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(YoutubeLiveSection);